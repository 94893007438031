<template>
  <div>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Slack Integration</h1>
      <p>
        Use our integration for Slack to bring your step and fitness challenges to your Slack team! Creating a challenge and inviting a team is a breeze and you can go live within a few minutes!
      </p>
      <p>
        Our integration for Slack is available as part of Free (up to 5 users) and <router-link to="/pricing">Pro plans</router-link>.
      </p>
      <h3>How it works</h3>
      <ol class="mb-4">
        <li><a href="https://api.sodisp.com/open/v1.0/slack/auth">Add CorporateFitness.app</a> to Slack.</li>
        <li>Add or invite our app for Slack to a (new) channel in your Slack workspace</li>
        <li>Create a challenge using our 5-step wizard and publish your event when all is ready to go</li>
        <li>Your team may now join the challenge using the <pre>/corporatefitness <strong>join</strong></pre> slash-command, or simply import an Excel file with names and email addresses. They will receive a personal invite to join the challenge and download our <router-link to="/get">iOS or Android app</router-link>.</li>
      </ol>
      <p>
        <strong>That's it, let's get moving now!</strong>
      </p>
      <p class="text-muted">
        Although our integration for Slack is fully working and available for all teams worldwide to use, you might see a banner from Slack on the permission page as the integration is currently under review by the Slack team and haven't been officially approved by them, yet. You can safely continue and enable the integration to start your Slack powered fitness challenge!
      </p>
      <p>
        <a href="https://api.sodisp.com/open/v1.0/slack/auth"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
      </p>

    </div>

    <div id="" class="mt-0 white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <v-sheet :elevation="4" class="pa-4"><v-img src="https://sodisp.imgix.net/web/slack/slack-home.png?w=1080" /></v-sheet>
          </v-col>
        </v-row>
      </div>
    </div>

    <div>
      <h2 class="subheader mt-8">Seamless Integration with Slack</h2>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" >
            <v-icon size="50" color="grey">fa-sync</v-icon>
            <h3 class="my-4">Quick Join</h3>
            <p>Inviting your team to a challenge has never been so simple! Your team can join using one simple slash-command.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4">
            <v-icon size="50" color="grey">fa-bullhorn</v-icon>
            <h3 class="my-4">Challenge Updates in Slack</h3>
            <p>All important events are automatically posted to your Slack channel to keep everyone up-to-date.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" >
            <v-icon size="50" color="grey">fa-poll-people</v-icon>
            <h3 class="my-4">Leaderboard updates</h3>
            <p>We'll post fresh new leaderboards every night on the Slack channel to keep everyone up-to-date with the latest progress.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" >
            <v-icon size="50" color="grey">fa-shoe-prints</v-icon>
            <h3 class="my-4">Step and Fitness Challenges</h3>
            <p>Create and manage your challenges right from within Slack.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" class="text-center align-center pa-4" to="/get">
            <v-icon size="50" color="grey">fa-mobile</v-icon>
            <h3 class="my-4">iPhone and Android app</h3>
            <p>Use our iPhone or Android app to track your progress.</p>
          </v-card>
        </v-col>
      </v-row>
      <h3>Secure and privacy friendly</h3>
      <p>
        We understand security and privacy are important to your organization and take these very seriously. We only have access to the (private or public) channels where you decide to add our Slack bot to and we only post to those channels. All data we store is encrypted at rest and encrypted in transit to ensure it stays secure at all time.
      </p>
      <p>
        Read our <router-link to="/privacypolicy">{{$t('account.register.terms_privacy_privacy')}}</router-link> and <router-link to="/termsofservice">{{$t('nav.footer.terms-of-service')}}</router-link> for all details.
      </p>
    </div>

    <div class="">
      <h2 class="subheader mt-8">Easy to use 'slash commands'</h2>
      <p>Control your event right from within Slack!</p>
      <v-list elevation="2" class="my-8 py-0">
        <v-list-item v-for="(item, idx) in slashCommands" :key="idx" :to="item.to" two-line>
          <v-list-item-content>
            <v-list-item-title><pre>/corporatefitness <strong>{{ item.command }}</strong></pre></v-list-item-title>
            <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </div>    

    <div>
      <h2 class="subheader mt-8">Integration with all major fitness trackers</h2>
      <p>
        We offer native integration with all major fitness wearables for automatic upload of activities. This greatly improves the experience of your participants! <br/>
        The app contains basic fitness tracking features which makes it a great choice for participants who are not yet using another tracker!
      </p>
      <SectionIntegrations />
    </div>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <a href="https://api.sodisp.com/open/v1.0/slack/auth" class="mr-4 pt-0"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
              <v-btn class="mt-n8" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      slashCommands: [
        { command: 'join', text: 'Get the personal join link for a member to join a challenge' },
        { command: 'status', text: 'Get the status of all connected events in a channel' },
        { command: 'connect', text: 'Connect an event to a channel' },
        //{ command: 'sync', text: 'Force a sync of all members to connected event(s)' },
        { command: 'disconnect', text: 'Disconnect all events from the channel' },
        { command: 'login', text: 'Login to your profile' },
        { command: 'manage', text: 'Open the event manager to manage your event' },
        { command: 'help', text: 'Get help and tips' },
      ],
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>